import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { graphql } from 'gatsby';
import Menu from '../components/menu';
import { renderRecipeCovers } from '../components/recipeCoversList';

const TagPage = ({ pageContext, data }) => {
  const { tag } = pageContext;

  return (
    <Layout>
      <SEO title={`Tag: ${tag} `} />
      <header>
        <Menu></Menu>
      </header>
      <h1 className="section-header">Przepisy z tagiem #{tag}</h1>
      {renderRecipeCovers(data)}
    </Layout>
  );
};

export default TagPage;

export const pageQuery = graphql`
  query($tag: String) {
    allPrismicRecipe(
      limit: 2000
      sort: { fields: first_publication_date, order: DESC }
      filter: { tags: { in: [$tag] } }
    ) {
      totalCount
      edges {
        node {
          data {
            name
            slug
            serves
            cover_image {
                alt
                copyright
                url
                fluid {
                ...GatsbyPrismicImageFluid
                }
            }
            }
        }
      }
    }
  }
`;
